import React from "react"
import axios from "axios";
import { Link } from "gatsby"
import icon1 from "../images/icon1.png"
import icon2 from "../images/icon2.png"
import icon3 from "../images/icon3.png"
import icon4 from "../images/icon4.png"
import icon5 from "../images/icon5.png"
import icon6 from "../images/icon6.png"
import icon7 from "../images/icon7.png"
import icon8 from "../images/icon8.png"
import icon9 from "../images/icon9.png"
import icon10 from "../images/icon10.png"
import icon11 from "../images/icon11.png"
import logo from "../images/logo.png"
import call from "../images/call.png"
import mail from "../images/mail.png"
import banner from "../images/Hero-banner-mobile.png"
import no from "../images/no.png"
import yes from "../images/yes.png"
import mobiledesk from "../images/mobile-desk.png"
// import appstore from '../images/appstore.png'
// import playstore from '../images/playstore.png'
import { Helmet } from "react-helmet"
import gantt from "../images/Gantt.png"
import { useState } from "react"

const HomePage = () => {
  const [visible, setVisible] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    industry: 'fmcg',
    country: 'IN',
  });

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      // const response = await axios.post('http://localhost/api/request', formData, {
        const response = await axios.post('https://routecap.com/api/request', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Handle success
      if (response.status === 200) {
        // Handle success
        console.log('Success:', response.data);
        window.alert("Demo Booked Successfully");
        
        // Redirect to homepage
        window.location.href = '/';
      }
    } catch (error) {
      // Handle error
      console.error('Error:', error);
      alert('There was an error submitting the form.');
    }
  };

  return (
    <div>
      {/* SECTION -1 */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>RouteCap</title>
      </Helmet>
      <div
        className="md:grid lg:grid-rows-6 md:grid-rows-8 lg:h-screen background"
        id="home"
      >
        <div className="md:row-span-1">
          <header class="lg:px-16 px-6 bg-purple flex flex-wrap items-center lg:py-0 py-2 nav-background ">
            <div class="flex-1 flex justify-between items-center">
              <a href="#home">
                <img src={logo} className="logo mt-4" />
              </a>
            </div>

            <label
              class="pointer-cursor lg:hidden block"
              onClick={() => setVisible(!visible)}
            >
              <svg
                class="fill-current "
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <title>menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </label>
            <div
              className={
                visible
                  ? "visible-class hidden lg:flex lg:items-center lg:w-auto w-full"
                  : "hidden-class hidden lg:flex lg:items-center lg:w-auto w-full"
              }
              id="menu"
            >
              <nav>
                <ul class="lg:flex items-center justify-between text-base text-white pt-4 lg:pt-0">
                  <li>
                    <Link
                      to="#home"
                      onClick={() => setVisible(!visible)}
                      class="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                    >
                      <strong>Home</strong>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/why-routecap/"
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400 lg:mb-0 mb-2"
                    >
                      <strong> Why RouteCap?</strong>{" "}
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/privacy-policy/"
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400 lg:mb-0 mb-2"
                    >
                      <strong>Privacy Policy</strong>
                    </Link>
                  </li> */}

                  {/* <li>
                    <Link
                      to="#planning"
                      onClick={() => setVisible(!visible)}
                      class="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                    >
                      Planning
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link
                      to="#features"
                      onClick={() => setVisible(!visible)}
                      class="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                    >
                      Features
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#mobileApp"
                      onClick={() => setVisible(!visible)}
                      class="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400 lg:mb-0 mb-2"
                    >
                      Mobile App
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#industries"
                      onClick={() => setVisible(!visible)}
                      class="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                    >
                      Industries
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#contact-us"
                      onClick={() => setVisible(!visible)}
                      class="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400 lg:mb-0 mb-2"
                    >
                      Contact Us
                    </Link>
                  </li> */}
                  {/* <div className="vertical-line"></div> */}
                  {/* <li>
                    <Link to="/why-routecap/" className="py-3 px-0">
                      <button className=" bg-transparent text-blue-700 border border-purple-500 why-routecap-button ">
                        Why RouteCap
                      </button>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link
                      to="/why-routecap/"
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400 lg:mb-0 mb-2"
                    >
                      <strong>
                        <u>Why RouteCap</u>{" "}
                      </strong>
                    </Link>
                  </li> */}
                </ul>
              </nav>
            </div>
          </header>
        </div>

        <div class="md:grid lg:row-span-5 lg:row-span-5 sm:grid sm:w-full ">
          <div class="lg:hidden md:w-full sm:w-full">
            <img src={banner} />
          </div>
          <div class="lg:w-1/2 md:w-full sm:w-full md:p-10 xs:p-6">
            {/* <h1 className="lg:w-3/4 md:w-full lg:p-3 pb-6 homeheading ">Product Name</h1> */}
            <p className="xl:w-3/4 lg:w-full md:w-full lg:p-3 homesubtitle ">
              RouteCap is a powerful algorithm-based optimization tool which can
              generate an efficient plan for your supply chain. The optimized
              plans generated adhere to time, zone, manpower and vehicle
              constraints as per your business operations requirement while
              balancing workload, minimizing the amount of resources utilized
              and maximizing savings.
            </p>
            <br></br>
            {/* <div class="lg:flex md:flex sm:grid  sm:p-4 button-div"> */}
            {/* <div class="md:pl-24 sm:pl-4 mt-4"> */}
            {/* <button class="bg-blue-200 text-white button1">
                                <a href="#contact-us"> Sign Up</a>
                            </button> */}
            {/* </div> */}
            <div>
              <a href="#contact-us">
                <button class="bg-transparent text-blue-700 border border-purple-500 button2 ">
                  Book a demo
                </button>
              </a>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>

      {/* SECTION - 2 */}

      <div class="mb-4 lg:pr-0 xxl:pr-0 md:p-10 xs:mt-10" id="modules">
        <h1 className="lg:w-1/2 md:w-3/4 heading xs:px-6">Modules</h1>
        <p className="lg:w-3/4 md:w-full body-text sub-subtitle py-8 xs:px-6">
          RouteCap offers multiple functionalities which serve various business
          requirements. These features have been encapsulated into modules which
          serve specific business requirements while avoiding inessential
          features.
        </p>

        <div class="lg:flex md:grid">
          <div class="lg:w-1/2 md:w-full sm:w-full">
            <div class="lg:w-full md:full modulesPoint pb-8 xs:px-6">
              <div class="md:w-full modulesPoint">
                <div className="flex mb-10 ">
                  <div class="icon-class w-1/4">
                    <img src={icon1} alt="Logo" className="img" />
                  </div>
                  <div className=" text-start pl-4 w-3/4">
                    <p className="body-text title">Powerful Algorithm</p>
                    <p className="body-text subtitle">
                      Find the most optimal solution to best utilize your
                      resources.
                    </p>
                  </div>
                </div>
              </div>
              <div class="md:w-full modulesPoint">
                <div className="flex mb-10 ">
                  <div class="icon-class w-1/4">
                    <img src={icon2} alt="Logo" className="img" />
                  </div>
                  <div className=" text-start pl-4 w-3/4">
                    <p className="body-text title">Ease of Use</p>
                    <p className="body-text subtitle">
                      The UI has been designed to improve efficiency and keep
                      the environment clutter free.
                    </p>
                  </div>
                </div>
              </div>

              <div class="md:w-full modulesPoint">
                <div className="flex mb-10 ">
                  <div class="icon-class w-1/4">
                    <img src={icon3} alt="Logo" className="img" />
                  </div>
                  <div className="text-start pl-4 w-3/4">
                    <p className="body-text title">User based access control</p>
                    <p className="body-text subtitle">
                      Ensure data and model security by controlling and limiting
                      who has access to the optimizer.
                    </p>
                  </div>
                </div>
              </div>

              <div class="md:w-full modulesPoint">
                <div className="flex mb-10 ">
                  <div className="icon-class w-1/4 ">
                    <img src={icon4} alt="Logo" className="img" />
                  </div>
                  <div className="text-start pl-4 w-3/4">
                    <p className="body-text title">User Friendly Mobile App</p>
                    <p className="body-text subtitle">
                      Ensure data and model security by controlling and limiting
                      who has access to the optimizer.Dispatch your optimized
                      plan to the delivery staff and also track the status of
                      each vehicle through the RouteCap mobile app on Android
                      and iOS.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="lg:w-1/2 lg:flex md:w-full sm:w-full lg:pr-0 md:grid lg:pl-0 lg:p-12 module-card ">
            <div class="fade-overlay">
              <div class="fade-overlay-card "></div>
            </div>
            <div className="custom-card-parent md:grid ">
              <div class="card custom_card m-4 ">
                <div class="p-8">
                  <h3 class="card_title">Operational Planning</h3>
                  <p class="body-text subtitle">
                    Plan your routes while adhering to business, time and
                    vehicle constraints. Maximize your operational savings while
                    reducing the time taken to generate the plan.
                  </p>
                </div>
              </div>
              <div class="card custom_card m-4 ">
                <div class="p-8">
                  <h3 class="card_title">Capacity Planning</h3>
                  <p class="body-text subtitle">
                    Determine the business capacity and demand for various
                    resources like manpower, vehicles etc. based on historical
                    data and past resource demand.{" "}
                  </p>
                </div>
              </div>
              <div class="card custom_card m-4 ">
                <div class="p-8">
                  <h3 class="card_title">Real-Time Planning</h3>
                  <p class="body-text subtitle">
                    Optimize your resources and routes based on live demand.
                    Maximize your savings by efficiently allocating vehicles to
                    real time requirement. Ex: Food delivery, cab allocation
                    etc.{" "}
                  </p>
                </div>
              </div>
              <div>
                <div className="spacer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION - 3 */}

      <div class="lg:flex md:grid mb-4 featureLeftBack " id="planning">
        <div class="lg:w-1/2 md:w-full gantt-image">
          {/* <img src={gantt} alt="Logo" className="img1 gantt-image" /> */}
        </div>

        <div class="lg:w-1/2 md:full featureRightBack ">
          <div className="featurePadding">
            <div class="md:grid mx-3">
              <h1 class="md:w-full pb-10 heading">Operational Planning</h1>

              {/* <div class="md:w-full modulesPoint "> */}
              <div className="flex mb-10 ">
                <div class="icon-class w-1/4">
                  <img src={icon5} alt="Logo" className="img" />
                </div>
                <div className=" text-start pl-4 w-3/4">
                  <p className="body-text title">Geocoding</p>
                  <p className="body-text subtitle">
                    Efficiently converts the provided addressed into coordinates
                    to calculate distances for an accurate plan.
                  </p>
                </div>
              </div>
              {/* </div> */}

              {/* <div class="md:w-full modulesPoint"> */}
              <div className="flex mb-10 ">
                <div class="icon-class w-1/4">
                  <img src={icon6} alt="Logo" className="img" />
                </div>
                <div className=" text-start pl-4 w-3/4">
                  <p className="body-text title">Multi-depot planning</p>
                  <p className="body-text body-text subtitle">
                    Plan your route across several different warehouses/depots
                    with each containing vehicles with different capacities.The
                    multi-trip functionality also allows vehicles to come back
                    to the depot to reload and served more orders.{" "}
                  </p>
                </div>
              </div>
              {/* </div> */}

              <div class="md:full modulesPoint">
                <div className="flex mb-10 ">
                  <div class="icon-class w-1/4">
                    <img src={icon7} alt="Logo" className="img" />
                  </div>
                  <div className=" text-start pl-4 w-3/4">
                    <p className="body-text title">Dynamic Gantt</p>
                    <p className="body-text subtitle">
                      Visualize the plan and route for the solution while also
                      viewing any violations of the constraints.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION -  */}

      <div id="features" class="md:p-10 xs:p-6">
        <h1 className=" text-center heading pb-4">Features</h1>
        <br />
        <table class="md:w-3/4 xs:w-full mx-auto">
          <thead>
            <tr className="table-row-color">
              <th>Features</th>
              <th>RouteCap</th>
              <th>Others</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Multi Objective Route Optimziation</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
            </tr>
            <tr>
              <td>Smart Order Clubbing with time windows</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
            </tr>
            <tr>
              <td>Multi depot planning</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
            </tr>
            <tr>
              <td>Map View</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
            </tr>
            <tr>
              <td>Driver Mobile Application</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
            </tr>
            <tr>
              <td>Real time optimization</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
            </tr>

            <tr>
              <td>Access mobile app data offline</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={no} class="feature-img" />
              </td>
            </tr>
            <tr>
              <td>Multi trip Optimization</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={no} class="feature-img" />
              </td>
            </tr>
            <tr>
              <td>Optimization for same day pick-up and drops</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={no} class="feature-img" />
              </td>
            </tr>
            <tr>
              <td>Dynamic Gantt with violations visibility</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={no} class="feature-img" />
              </td>
            </tr>

            <tr>
              <td>Unassigned planning on dynamic gantt</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={no} class="feature-img" />
              </td>
            </tr>
            <tr>
              <td>Split Order delivery in same time window</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={no} class="feature-img" />
              </td>
            </tr>
            <tr>
              <td>Accurate Distance-Time matrix using cluster zones</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={no} class="feature-img" />
              </td>
            </tr>
            <tr>
              <td>Performance driven custom Optimization parameters</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={no} class="feature-img" />
              </td>
            </tr>
            <tr>
              <td>Personalized Solution support</td>
              <td>
                <img src={yes} class="feature-img" />
              </td>
              <td>
                <img src={no} class="feature-img" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* SECTION -  */}
      <div id="mobileApp" class="p-10 featureLeftBack">
        <h1 className=" text-center heading pb-10">Mobile App</h1>
        <div className="lg:flex md:flex sm:grid">
          <div className="lg:w-3/5 md:w-full sm:full">
            <img src={mobiledesk} className="img mobile-screen" />
          </div>

          <div className="lg:w-2/5 md:w-full sm:full mobile-copy">
            <p className="body-text subtitle">Driver live tracking</p>
            <hr className="horizontal-line" />
            <p className="body-text subtitle">
              Dispatch delivery details to driver
            </p>
            <hr className="horizontal-line" />
            <p className="body-text subtitle">Aid driver navigation</p>
            <hr className="horizontal-line" />
            <p className="body-text subtitle">Multi-language support</p>
            <hr className="horizontal-line" />
            <p className="body-text subtitle">Android and iOS support</p>
            <hr className="horizontal-line" />

            {/* <div className="lg:flex md:flex sm:grid mobile-store-div">
                    <div>
                        <img src={playstore} className="playstore-img"/>
                    </div>
                    <div>
                        <img src={appstore} className="appstore-img"/>
                    </div>
                </div> */}
          </div>
        </div>
      </div>

      {/* SECTION - 4 */}

      <div id="industries" class="md:p-10 xs:p-6">
        <h1 className=" text-center  heading">Industries</h1>
        {/* <div class="flex pt-10"> */}
        {/* <div className=" m-auto"> */}
        <div className="flex flex-wrap sm:pt-20 xs:pt-6">
          <div className="xxl:w-1/4 lg:w-1/2 md:w-1/2 sm:w-full industry-col-left ">
            <div className="rectbox ">
              <div className=" flex card-div">
                <img src={icon8} alt="Logo" className="img-box" />
              </div>

              <div className="rightRect rounded-r-lg p-6">
                <p className=" title card-title">CPG and FMCG</p>
                <p className="body-text subtitle">
                  <ul>
                    <li className="list-item">
                      <span> Accurate geo-coding of locations</span>
                    </li>
                    <li className="list-item">
                      <span>Add business constraints and parameters</span>
                    </li>
                    <li className="list-item">
                      <span>Maximize the deliveries and customers served</span>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div className="xxl:w-1/4 lg:w-1/2 md:w-1/2 sm:w-full industry-col-right ">
            <div className=" rectbox ">
              <div className=" flex card-div">
                <img src={icon9} alt="Logo" className="img-box" />
              </div>
              <div className="rightRect rounded-r-lg p-6">
                <p className=" title card-title">Retail</p>
                <p className="body-text subtitle">
                  <ul>
                    <li className="list-item">
                      <span> Accurate geo-coding of locations</span>
                    </li>
                    <li className="list-item">
                      <span>
                        Cluster deliveries based on type of order/material{" "}
                      </span>
                    </li>
                    <li className="list-item">
                      <span> Add business constraints and parameters</span>
                    </li>
                    <li className="list-item">
                      <span> Minimize the operational costs </span>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div className="xxl:w-1/4 lg:w-1/2 md:w-1/2 sm:w-full industry-col-left">
            <div className=" rectbox ">
              <div className=" flex card-div">
                <img src={icon10} alt="Logo" className="img-box" />
              </div>
              <div className="rightRect rounded-r-lg p-6">
                <p className=" title card-title">Courier and Parcel delivery</p>
                <p className="body-text subtitle">
                  <ul>
                    <li className="list-item">
                      <span>Plan for both pick-up and delivery of parcels</span>
                    </li>
                    <li className="list-item">
                      <span> Accurate geo-coding of locations</span>
                    </li>
                    <li className="list-item">
                      <span> Add business constraints and parameters</span>
                    </li>
                    <li className="list-item">
                      <span>
                        Minimize the turnaround time and improve delivery
                        efficiency
                      </span>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div className="xxl:w-1/4 lg:w-1/2 md:w-1/2 sm:w-full industry-col-right">
            <div className=" rectbox ">
              <div className=" flex card-div">
                <img src={icon11} alt="Logo" className="img-box" />
              </div>
              <div className="rightRect rounded-r-lg p-6">
                <p className=" title card-title">Other Application sectors</p>
                <p className="body-text subtitle">
                  <ul>
                    <li className="list-item">
                      {" "}
                      <strong> Planned deliveries </strong>
                    </li>
                    <li className="sub-list-item">
                      <span> Water bottle delivery</span>{" "}
                    </li>
                    <li className="sub-list-item">
                      <span> Airport drops and pick-ups</span>{" "}
                    </li>
                    <li className="sub-list-item">
                      <span> Leisure/travel/sports pickups and drops</span>{" "}
                    </li>
                  </ul>
                  <ul>
                    <li className="list-item">
                      <strong>Dynamic/Real time optimization </strong>
                    </li>
                    <li className="sub-list-item">
                      <span> Food deliveries , Cab pick and drops</span>{" "}
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>

      {/* SECTION - 5 */}
      <div class="md:p-10 xs:p-6 lg:h-screen featureLeftBack" id="contact-us">
        <div class="lg:w-1/2 md:w-full sm:w-full">
          <h1 className="lg:w-3/4 md:full heading">Contact Us</h1>
          <p className="xl:w-3/4 lg:w-3/4 body-text sub-subtitle py-8">
            Kindly enter your details below to find out how RouteCap can help
            reduce operational costs, save time and create a positive impact on
            your business.{" "}
          </p>
        </div>

        <div className="lg:flex md:grid">
          <div class="lg:w-1/2 md:full md:pt-10 pb-8">
            <div class="modulesPoint lg:w-4/5 sm:w-full">
              <div className="grid md:mb-4 ">
                <div className="flex lg:w-3/4 md:full text-start ">
                  <div class="flex pt-3 pb-3 w-full">
                    <span class="mx-2 title">UAE</span>
                  </div>

                  <div class="flex pt-3 pb-3 w-full">
                    <span class="mx-2 title">INDIA</span>
                  </div>
                </div>

                <div className="flex lg:w-3/4 md:full text-start ">
                  <div class="flex pt-3 pb-3 w-full">
                    <img src={call} alt="Logo" className="img-contact" />
                    <span class="mx-2 contact-text">(+971) 585919346</span>
                  </div>

                  <div class="flex pt-3 pb-3 w-full">
                    <img src={call} alt="Logo" className="img-contact" />
                    <span class="mx-2 contact-text"> (+91) 7411585707</span>
                  </div>
                </div>
                <hr class="solid" />

                <div className="flex md:w-3/4 text-start ">
                  <div class="flex pt-6 pb-3 w-full">
                    <img src={mail} alt="Logo" className="img-contact" />
                    <span class="mx-2 contact-text">bd@routecap.com</span>
                  </div>
                </div>
              </div>
              <hr class="solid" />
            </div>
          </div>

          <div class="lg:w-1/2 md:w-full flex">
            <form className="w-full" id="contact-form" method="post" onSubmit={handleSubmit}>
              <div className="md:flex">
                <div className="group w-1/2">
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label className="label-style">First Name</label>
                </div>

                <div className="group w-1/2 left-input">
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label className="label-style">Last Name</label>
                </div>
              </div>

              <div className="md:flex">
                <div className="group w-1/2">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label className="label-style">Work Email</label>
                </div>

                <div className="group w-1/2 left-input">
                  <input
                    type="text"
                    pattern="[789][0-9]{9}"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label className="label-style">Contact number</label>
                </div>
              </div>

              <div className="group">
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  required
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label className="label-style">Company Name</label>
              </div>

              <div className="md:flex">
                <div className="group-select w-1/2">
                  <select
                    className="select-text"
                    name="industry"
                    value={formData.industry}
                    onChange={handleChange}
                    required
                  >
                    <option value="fmcg">CPG and FMC</option>
                    <option value="consulting">Consulting</option>
                    <option value="healthcare">Healthcare</option>
                    <option value="pharma">Pharma</option>
                    <option value="oil and gas">Oil and Gas</option>
                    <option value="logistics">Logistics</option>
                    <option value="others">Others</option>
                  </select>
                  <span className="select-highlight"></span>
                  <span className="select-bar"></span>
                  <label className="select-label">Industry</label>
                </div>

                <div className="group-select left-input w-1/2">
                  <select
                    className="select-text"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  >
                    <option value="IN">India</option>
                    <option value="US">USA</option>
                    <option value="AE">UAE</option>
                    <option value="others">Others</option>
                  </select>
                  <span className="select-highlight"></span>
                  <span className="select-bar"></span>
                  <label className="select-label">Country</label>
                </div>
              </div>

              <div className="lg:pt-8 lg:pl-0 md:pl-24 sm:pl-4 xs:pt-6 button-div-submit">
                <button
                  className="bg-blue-200 text-white button1"
                  id="demo-button"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <footer className="xl:pt-0 xs:pt-10">
        <div>
          <div className="copyright">
            <p> Copyright © 2020 Ormae. All rights reserved</p>
          </div>
          <div className="social-icons"></div>
        </div>
      </footer>
    </div>
  )
}

export default HomePage
